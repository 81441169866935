<main>
    <!--espacio para el header-->
       <div class="space-top"></div>
 
      <section class="container-fluid bg-title" style="padding: 0px;">
        <div class="container-full container ">
       <div class="row pt-3" style="margin-right: -15px !important;
    margin-left: -15px !important;">
          <div class="col-md-12 col-lg-3 text-center text-md-left">
            <h1 class="page-title">Noticias</h1>
          </div>
        
          <div class="col-md-6 col-lg-4"></div>
          <div class="col-md-6 col-lg-4">
            <!-- Search form-->
            <form class="form-inline d-flex justify-content-center md-form form-sm mt-0"><i class="fas fa-search" aria-hidden="true"></i>
              <input class="form-control form-control-sm ml-3 w-75" type="text" placeholder="Buscar" aria-label="Buscar"/>
            </form>
                </div>
          </div>
        </div>
        <!--all cards-->
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-project project-zoom-effect" style="background-image: url(assets/img/formacion/online.jpg)">
              <div class="card-project-content container">
                <h2 class="card-project-title"> <a [routerLink]="['/post']">Título del noticia</a></h2>
                <h3 class="card-project-subtitle">Autor - Fecha</h3>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

 <app-footer></app-footer>