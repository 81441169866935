import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

import { ProjectsService } from '../../services/projects.service';
import {global} from  "../../services/globar";
declare var Hammer: any; 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ProjectsService]
})

export class HomeComponent implements OnInit {
public page_title: string;
public slider;
public urlApi;
public date;
public year;
  constructor(
  private _projectsService : ProjectsService
  ) { 
  this.page_title = 'Inicio';
  this.urlApi = global.urlTow;
  }


  ngOnInit(): void {
 
  if($(window).width() > 576){
  $('.main-header-nav').css('margin-left', '-32px');
  }
  this.getProjectsCoverPrint();
   this.date = new Date();
  this.year = this.date.getFullYear();

  }
  
 getProjectsCoverPrint(){
  this._projectsService.getProjectsCover().subscribe(
  
   response => {
   this.slider = response;
   
   }
  
  );
  }
}
