import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {global} from  "./globar";

@Injectable()
export class UserService {

    public url: string;

    constructor(
    public _http: HttpClient
    ){
    this.url = global.url;
    }
    
   register(text): Observable<any>{
   let json = JSON.stringify(text);
   let params = 'json=' + json;
   
   let headers = new HttpHeaders().set('Content-Type', 'Application/x-www-form-urlencoded');
   
   return this._http.post(this.url, params, {headers: headers});
   }
}