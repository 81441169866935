import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {global} from  "./globar";

@Injectable()
export class ContactService {

    public url: string;

    constructor(
    public _http: HttpClient
    ){
    this.url = global.url;
    }


    getContact():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?contact=true', {headers : headers});
    } 

    
  

}