import { Component, OnInit } from '@angular/core';
declare var AOS: any; 
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  $('.main-header-nav').css('margin-left', '-15px');
  //scroll
    AOS.init({
        once: true,
    });
  }

}
