
  <header class="container-fluid fixed-top main-header">
          <nav class="row navbar navbar-expand-lg navbar-light main-header-nav">
            <div class="container-full container"><a class="navbar-brand" [routerLink]="['./']"><img src="https://www.volcanstudio.com/assets/img/logo-volcan.png" alt="logo"/></a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
              <div class="collapse navbar-collapse" id="navbarNav" onclick="$(this).removeClass('show')">
            <ul class="navbar-nav ml-auto">
                  <li class="nav-item"><a class="nav-link" [routerLink]="['./']">Inicio</a></li>
                  <li class="nav-item"><a class="nav-link" [routerLink]="['./proyectos']">Proyectos</a></li>
                  <li class="nav-item"><a class="nav-link" [routerLink]="['./estudio']">Estudio</a></li>
                    <li class="nav-item"><a class="nav-link" [routerLink]="['./servicios']">Servicios</a></li> 
                  <li class="nav-item"><a class="nav-link" [routerLink]="['./formacion']">Formación</a></li>
                 <li class="nav-item"><a class="nav-link" href="./blog">Noticias</a></li> 
                 
                  <li class="nav-item"><a class="nav-link" [routerLink]="['./contacto']">Contacto</a></li>
                </ul>
              </div>
            </div>
          </nav>
        </header>


<router-outlet></router-outlet>

