import { Component, OnInit } from '@angular/core';
import {global} from  "../../services/globar";
import { StudyService } from '../../services/study.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var AOS: any; 
declare var Swiper: any; 
declare var Hammer: any; 
@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css'],
  providers: [StudyService]
})
export class StudyComponent implements OnInit {
public dominio;
public study;
public studyCarrousel;
public urlApi;
public studyAwards;
public studyTeam;
public studyImgRsc;
  constructor(
   private route: ActivatedRoute,
  private _router: Router,
    private _studyService : StudyService,
  ) {
  this.dominio = global.dominio;
  this.urlApi = global.urlTow;
  }

  ngOnInit(): void {


  this.getStudy();
  
 $('.main-header-nav').css('margin-left', '-15px');
 

    //scroll
    AOS.init({
        once: true,
    });
    
 

    
    // Sliders / Carousel
  //si existe la clase
  if ($(".swiper-container-columns-4")[0]){
    
    //slider de  4 columnas
    var swiper_columns_4 = new Swiper('.swiper-container-columns-4', {
      //slidesPerView: 2,
      spaceBetween: 10,
      //para evitar error de tabs
      observer:true,
      observeParents:true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      // init: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }
    });
  }

  }

  activeSlideChange(){
    $('.active > .video-fluid').each(function( index ) {
      
      this.currentTime = 0;
    
    });
  }

  heightVideo(){
    var heightIf = 1080 * $(window).width() / 1920;
  if(heightIf > $(window).height()){
  var height = $(window).height() - 136;
  $('.carousel').css('height', height);
  $('.carousel').css('overflow', 'hidden');
  }else{
   var height = 1080 * $(window).width() / 1920;
  $('.carousel').css('height', height);
  $('.carousel').css('overflow', 'hidden');
  }

$('.active > .video-fluid').each(function( index ) {
  
  this.play();
});

    }
    

  
  getStudy(){
  
 this.route.params.subscribe(params => {
    this._studyService.getStudy().subscribe(
  
  response => {
  this.study = response;
  
  }
  );
  
  this.studyCarrousel = this._studyService.getStudyCarrousel(); 
  
  this._studyService.getStudyAwards().subscribe(
  
  response => {
  this.studyAwards = response;
  }
  ); 
  this._studyService.getStudyTeam().subscribe(
  
  response => {
  this.studyTeam = response;
  
  }
  );  
  
  this._studyService.getStudyImgRsc().subscribe(
  
  response => {
  this.studyImgRsc = response;
  
  }
  );
  
  
  });
}



}
