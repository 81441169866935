//Imports necesarios
import { ModuleWithProviders } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

//Importar componentes

import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectComponent } from './components/project/project.component';
import { StudyComponent } from './components/study/study.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { PostComponent } from './components/post/post.component';
import { TrainingComponent } from './components/training/training.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

//Definir las rutas
const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'inicio', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registro', component: RegisterComponent},
  {path: 'proyectos', component: ProjectsComponent},
  {path: 'proyectos/buscar/:search', component: ProjectsComponent},
  {path: 'proyectos/:category', component: ProjectsComponent},
  {path: 'proyecto/:url', component: ProjectComponent},
  {path: 'estudio', component: StudyComponent},
  {path: 'formacion', component: TrainingComponent},
  {path: 'contacto', component: ContactComponent},
  {path: 'privacidad', component: PrivacyComponent},
  {path: 'servicios', component: ServicesComponent},
  {path: 'post', component: PostComponent},
  {path: '**', component: ErrorComponent},
  
];

//Exportar configuración
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
});