<main>
      <!--espacio para el header-->
      <div class="space-top"></div>
      <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3" style="margin-left: -17px;">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">Contacto</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="container-fluid">
        <!-- localizacion-->
        <div class="row">
          <div class="col-lg-6 px-0">
            <!-- Google map-->
            <div class="contacto-map " style="min-height: 400px;" id="map">
             
              
            </div>
            <!-- Google Maps-->
          </div>
          <div class="col-lg-6 contacto-black" style="background: url('assets/img/fondo-mapa-contacto.jpg'); background-repeat: no-repeat;background-size: cover;">
            <div class="py-5" style="font-size: 17px;color: #f2f2f2;font-weight: 300;" data-aos="fade-up" data-aos-duration="1200" [innerHtml]="contact.contact_content">
        
            </div>
          </div>
        </div>
      </section>
    </main>
 <app-footer></app-footer>