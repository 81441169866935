import { Component, OnInit } from '@angular/core';
import {global} from  "../../services/globar";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    public dominio;
    public date;
    public year;
  constructor() { 
  this.dominio = global.dominio;
  }

  ngOnInit(): void {
  this.date = new Date();
  this.year = this.date.getFullYear();
  }

}
