<main>
    <style>
        .row{
            margin: 0px !important;
        }
    </style>
      <!--espacio para el header-->
      <div class="space-top"></div>
 
      <section class="container-fluid bg-title" style="padding: 0px;">
        <div class="container-full container ">
       <div class="row pt-3" style="margin-right: -15px !important;
    margin-left: -18px !important;">
          <div class="col-md-12 col-lg-3 text-center text-md-left">
            <h1 class="page-title">Proyectos</h1>
          </div>
          <div class="col-md-6 col-lg-4 category-mobile">
            <select class="mdb-select  md-form mx-auto page-proyectos-select" [ngModel]="percent" (ngModelChange)="change($event)" name="categorias">
              <option value=""selected="" disabled>Categorías</option>
              <option value="arquitectura" >Arquitectura</option>
                <option value="reformas">Reformas</option>
              <option value="diseno-industrial">Diseño industrial</option>
              <option value="comunicacion-corporativa">Comunicación Corporativa</option>
              
            </select>
          </div>   
           
           <div class="col-md-6 col-lg-6 category-pc">
                <ul class="nav-projects-category">
                    <li style="border: none;"><a [routerLink]="['/proyectos', 'arquitectura']">Arquitectura</a></li>
                    <li><a [routerLink]="['/proyectos', 'reformas']">Reformas</a></li>
                    <li><a [routerLink]="['/proyectos', 'diseno-industrial']">Diseño Industrial</a></li>
                    <li><a [routerLink]="['/proyectos', 'comunicacion-corporativa']">Comunicación Corporativa</a></li>
                   
               
               </ul>
          </div> 
     
          <div class="col-md-6 col-lg-3">
            <!-- Search form-->
            <form (ngSubmit)="onSubmit()" class="form-inline d-flex justify-content-center md-form form-sm mt-0 form-aline" ><i class="fas fa-search" aria-hidden="true" style="color:#949494"></i>
              <input class="form-control form-control-sm ml-3 w-85" [(ngModel)]="SearchModel.search" name="search" type="text" placeholder="Buscar" aria-label="Search"/>
            </form>
          </div>
        </div>
    </div>
          
    </section>
     <section class="container-fluid" style="padding: 0px; min-height: 650px !important;">
        <!--all cards-->
               <div [innerHtml]="empty"></div>
        <div class="row no-gutters">
      
            
            
          <div class="col-md-6" data-aos="" data-aos-duration="1200" [attr.data-aos]="project[0].effect"  *ngFor="let project of projects">
            <!--.card-project-->
              <a [routerLink]="['/proyecto', project.project_url]">
            <div class="card-project project-zoom-effect" style="background-image: url({{url + project[0].image}})">
              <div class="card-project-content container">
                <h2 class="card-project-title" style="font-size: 40px;font-weight: 600;line-height: 45px;"> {{project.project_title}}</h2>
                <h3 class="card-project-subtitle" style="font-size: 25px;font-weight: 300;line-height: 35px;">{{project.project_subtitle}}</h3>
                <ul class="card-project-categories">
                  <li style="font-size: 14px;font-weight: 400;">{{project.project_category}}</li>
                  
                </ul>
              </div>
            </div>
                </a>
            <!-- ./card-project-->
          </div>
<div style="position: fixed;top:0px;width:100%;height:100%;background: rgba(255,255,255,0.5);display: none" class="loadMore"></div>
        </div>
      </section>
    </main>

 <app-footer></app-footer>