import { Component, OnInit } from '@angular/core';
import {global} from  "../../services/globar";
import { ServicesService } from '../../services/services.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var AOS: any; 
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  providers: [ServicesService]
})
export class ServicesComponent implements OnInit {
    public service;
    public serviceCarrousel;
    public subservices;
    public urlApi;
    public background: string;
 
  constructor(
   private route: ActivatedRoute,
  private _router: Router,
    private _servicesService : ServicesService,
  ) {
  this.urlApi = global.urlTow;
  }

  ngOnInit(): void {
 $('.main-header-nav').css('margin-left', '-15px');
  this.getService();
    //scroll
    AOS.init({
        once: true,
    });
  }
  
  getService(){
  this.background = "background:";
 this.route.params.subscribe(params => {
    this._servicesService.getServices().subscribe(
  
  response => {
  this.service = response;
  
  }
  );  
  
  this._servicesService.getCarrouselService().subscribe(
  
  response => {
  this.serviceCarrousel = response;
  
  }
  ); 
  
  
  this._servicesService.getSubservices().subscribe(
  
  response => {
  this.subservices = response;
 
  }
  );
  });
}


}
