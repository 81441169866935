    <footer id="footer" class="footer main-footer">
      <div class="container-full container">
        <div class="row" style="font-size: 11px;">
          <div class="flex-lg-fill"><img src="{{dominio}}/assets/img/volcan-white.png" alt="logo" style="width: 30px"><span class="pl-3">&copy; Copyright {{year}} , All Right Reserved.</span></div>
          <div class="flex-lg-fill">
            <ul class="main-footer-links main-footer-links-center">
              
              <li><a [routerLink]="['/privacidad']">Privacidad</a></li>
              
            </ul>
          </div>
          <div class="flex-lg-fill">
            <ul class="main-footer-links main-footer-icons">
              <li><a href="https://www.facebook.com/volcanstudioarquitectura/" target="_blank"> <i class="fab fa-facebook"></i></a></li>
              <li><a href="https://www.instagram.com/mariadelgado_volcanstudio/" target="_blank"> <i class="fab fa-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/company/volcanstudioarquitectura" target="_blank"> <i class="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>