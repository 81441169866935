<main>
      <!--espacio para el header-->
      <div class="space-top"></div>
      <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">Volcán Studio</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- main card-->
      <section class="row no-gutters min-header" >
        <div class="col-md-12">
          <!--.card-project-->

            
            <mdb-carousel #carouselRef  [animation]="'slide'" [interval]="0" (activeSlideChange)="activeSlideChange()" >
  <mdb-carousel-item *ngFor="let carrousel of studyCarrousel | async; let first = first;">
    <video class="video-fluid"  preload playsinline  muted="true" (ended)="carouselRef.nextSlide()" (loadstart)="heightVideo()"  src="{{urlApi + carrousel.upload_url}}">
     
    </video>
  </mdb-carousel-item>

</mdb-carousel>
          <!-- ./card-project-->
        </div>
      </section>
      <!-- ./main card-->
      <!-- main section-->
      <section class="container">
        <!--description-->
        <div class="row py-4 mt-5">
          <div class="col-12" data-aos="fade-up" data-aos-duration="1200" style="color: #404040;font-size: 17px;font-weight: 400;line-height: 30px;" [innerHtml]="study.study_intro">
              
              
          </div>
          <div class="col-12 font-weight-light" data-aos="fade-up" data-aos-duration="1200" style="color: #404040;font-size: 16px;font-weight: 300;line-height: 30px;" [innerHtml]="study.study_content"> 
           
              
          
          </div>
        </div>
    </section>
        <!-- ./description-->
        <!--premios-->
    <section class="container-fluid" data-aos="fade-up" data-aos-duration="1200" *ngIf="studyAwards;">
        <div class="row" style="background: radial-gradient(circle, #F2F2F2 0%, #E0E0E0 46%, #BDBDBD 100%);justify-content:center">
        <div class="formacion-premios" *ngFor="let image of studyAwards;" >
          <div class="formacion-premios-item" style="max-width: 250px;"><img class="img-fluid" src="{{urlApi + image.upload_url}}" style="width: 237px;" alt="DICA_inter_comercial_oro"/>
            <p style="font-size: 13px;color: #777;">{{image[0].description}}</p>
            </div>
        </div>
        </div>
    </section>
        <!-- ficha team-->
    <section class="container">
        <div class="row pb-5 pt-5">
          <ul class="nav nav-tabs justify-content-center volcan-nav" role="tablist" data-aos="fade-up" data-aos-duration="1200">
            <li class="nav-item"><a class="nav-link active" id="equipo-tab" data-toggle="tab" href="#equipo" role="tab" aria-controls="equipo" aria-selected="true">Equipo</a></li>
           <!-- <li class="nav-item"><a class="nav-link" id="clientes-tab" data-toggle="tab" href="#clientes" role="tab" aria-controls="clientes" aria-selected="false">Clientes</a></li> -->
            <li class="nav-item"><a class="nav-link" id="rsc-tab" data-toggle="tab" href="#rsc" role="tab" aria-controls="rsc" aria-selected="false">RSC</a></li>
          </ul>
          <div class="tab-content w-100 pt-0" data-aos="fade-up" data-aos-duration="1200">
             <!-- equipo-->
            <div class="tab-pane fade show active" id="equipo" role="tabpanel" aria-labelledby="equipo-tab">
              <!-- Swiper-->
              <div class="swiper-container pt-4 swiper-container-columns-4">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" *ngFor="let image of studyTeam;">
                    <div class="card-project card-project-team" style="background-image: url({{urlApi + image.upload_url}});height: 150px;">
                      <div class="card-project-content container">
                        <h2 class="card-project-title" style="font-size: 20px;">
                           {{image[0].name}}</h2>
                      </div>
                    </div>
                      <p  style="font-size:10px;margin-top:7px;">{{image[0].description}}</p>
                  </div>
      
                </div>
                <div class="swiper-pagination"></div>
                <!-- /.swiper-wrapper-->
              </div>
            </div>
            <!-- ./equipo-->
            <!-- clientes-->
            <div class="tab-pane fade" id="clientes" role="tabpanel" aria-labelledby="clientes-tab">
              <div class="row pb-5 pt-4">
                <div class="col-md-6 col-lg-3 pb-4">
           
                </div>
                <div class="col-md-6 col-lg-3 pb-4">
                  <div class="card-project card-project-team" style="background-image: url({{dominio}}/assets/img/team/team_1.jpg)">
                    <div class="card-project-content container">
                 
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 pb-4">
                  <div class="card-project card-project-team" style="background-image: url({{dominio}}/assets/img/team/team_1.jpg)">
                    <div class="card-project-content container">
                     
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 pb-4">
          
                </div>
              </div>
            </div>
            <!-- ./clientes-->
            <!-- rsc-->
            <div class="tab-pane fade" id="rsc" role="tabpanel" aria-labelledby="rsc-tab" style="color: #404040;font-size: 16px;font-weight: 300;line-height: 30px;">
                <div [innerHtml]="study.study_rsc">
         
                    
                </div>
              <div class="pb-5 pt-4" style="margin-left: -60px;">
             
               <div style="margin: auto;text-align: center;display: flex;justify-content:center;">
                   
                   <div *ngFor="let image of studyImgRsc;">
                  <div class="card-project card-project-team liner-none" style="background-image: url({{urlApi + image.upload_url}});width:120px;height: 120px;margin-left: 60px;">
                    <div class="card-project-content container">
                     
                    </div>
                  </div>
                </div>
                  </div>
              </div>
            </div>
            <!-- ./rsc-->
          </div>
        </div>
      </section>
      <!-- ./main section-->
    </main>
 <app-footer></app-footer>