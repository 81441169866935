import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {global} from  "./globar";

@Injectable()
export class ProjectsService {

    public url: string;

    constructor(
    public _http: HttpClient
    ){
    this.url = global.url;
    }


    getProjects(limit):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?projects='+limit, {headers : headers});
    }
    
    getProjectsCategory(category):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?projectsCategory='+category, {headers : headers});
    }
    getProjectsSearch(search):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?searchProject='+search, {headers : headers});
    }
    
    getProject(urlProject):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?project='+urlProject, {headers : headers});
    }
    
    getSliderProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?sliderProject='+token+'&section=slider_projects', {headers : headers});
    } 
    
    getGalleryOneProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?galleryoneProject='+token+'&section=gallery1_projects', {headers : headers});
    } 
    
    getGalleryTwoProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?gallerytwoProject='+token+'&section=gallery2_projects', {headers : headers});
    } 
    
    getComparativeProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?comparativeProject='+token, {headers : headers});
    }
    
   getDataOneProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?dataProject='+token+'&column=1', {headers : headers});
    } 
    
    getDataTwoProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?dataProject='+token+'&column=2', {headers : headers});
    }
 getMediaOneProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?mediaProject='+token+'&column=1', {headers : headers});
    } 
    
    getMediaTwoProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?mediaProject='+token+'&column=2', {headers : headers});
    } 
    
    getCreditsOneProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?creditsProject='+token+'&column=1', {headers : headers});
    } 
    
    getCreditsTwoProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?creditsProject='+token+'&column=2', {headers : headers});
    }
    
    getAwardsOneProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?awardsProject='+token+'&column=1', {headers : headers});
    } 
    
    getAwardsTwoProject(token):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?awardsProject='+token+'&column=2', {headers : headers});
    }
 getProjectCategory(id, category):Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?categoryProject='+category+'&id_project='+id, {headers : headers});
    } 
    
    getProjectsCover():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?CoverProject=true', {headers : headers});
    }

}