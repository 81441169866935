 <main>
      <!-- main card-->
      <section class="row no-gutters min-header">
        <div class="col-md-12">
          <!--.card-project-->
       
              <mdb-carousel class="carousel-responsive" [animation]="'slide'" [interval]="countSlider">
  <mdb-carousel-item  *ngFor="let carrousel of slider; let first = first;">
     <div class="home-card" style="background-image: url({{urlApi + carrousel.upload_url}})">
        
        </div>
 
  </mdb-carousel-item>
                         <div class="home-card-content container">
            <h2 class="home-card-title" style="font-size: 40px;font-weight: 600;line-height: 45px;"> <a >{{project.project_title}}</a></h2>
            <h3 class="home-card-subtitle" style="font-size: 25px;font-weight: 300;line-height: 35px;">{{project.project_subtitle}}</h3>
            <ul class="home-card-categories" style="font-size: 14px;font-weight: 400;">
              <li>{{project.project_category}}</li>
             
            </ul>
          </div>
                  <div style="position: absolute;bottom:50px" #bajarContenido></div>
 <div class="arrow-down" (click)="scrollToElement(bajarContenido)">
   <ng-lottie [options]="options"  width="50px"></ng-lottie>
         
            </div>
</mdb-carousel>
          <!-- ./card-project-->
        </div>
      </section>
      <!-- ./main card--> 
     
      <!-- main section-->
      <section class="container" >
        <div class="row" data-aos="fade-up" data-aos-duration="1200">
          <div class="col-12 text-center">
            <ul class="nav justify-content-center py-4 smooth-scroll">
              <li class="nav-item" *ngIf="galleryOnePreviw;"><a class="nav-link"  (click)="scrollToElement(galerias)">Galerías</a></li>
              <li class="nav-item"><a class="nav-link" (click)="scrollToElement(ficha_tecnica)">Ficha Técnica</a></li>
              <li class="nav-item" *ngIf="project.project_locationText"><a class="nav-link" (click)="scrollToElement(localizacion)">Localización</a></li>
            </ul>
          </div>
        </div>
        <!--description-->
        <div class="row py-4">
          <div class="col-12"  [innerHtml]="project.project_textIntro" data-aos="fade-up" data-aos-duration="1200" style="font-size: 17px;font-weight: 400;line-height: 30px;">
       
          </div>
          <div class="col-12 font-weight-light" [innerHtml]="project.project_text" data-aos="fade-up" data-aos-duration="2000" style="font-size: 16px;font-weight: 300;line-height: 30px;">
  
          </div>
        </div>
        <!-- ./description-->
          <!--comparador-->
        <div class="row" id="comparative" data-aos="fade-up"  data-aos-duration="3000">
          <div class="col-12 text-center" >
            <div class="b-dics comparador_1" *ngIf="showVideo == false"><img src="{{urlApi + comparativeOne}}"/><img  src="{{urlApi + comparativeTwo}}"/></div>
            
          </div>
            
            <div class="col-12 text-center" >
            <div *ngIf="comparativeThree">
             
           <video id="video-project" style="width:100%;margin-top: 20px"  (loadstart)="startVideo()"  muted="true" playsinline controls="true"  autoplay="false"  volume="0" src="{{urlApi + comparativeThree}}" (click)="playVideoIf()">

                </video>
              </div>
            
          </div>
        </div>
        <!-- galerias-->
        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="3000" > 
            <div style="position: absolute;top:-20%;" #galerias></div>
          <div class="col-md-6 active-gallery" id="galeria_tecnica">
              <div class="pb-5 pt-5" *ngIf="galleryOnePreviw">
            <!--.card-gallery-->
            <div class="card-gallery cursor-pointer"  style="background-image: url({{urlApi + galleryOnePreviw}})">
                <div class="d-none galleryOneUrls" *ngFor="let url of galleryOne;">
                <div class="d-none url-image">{{urlApi + url.upload_url}}</div>
                </div>
              <div class="card-gallery-content container">
                <h3 class="card-gallery-title">
                   Galería <br/>Técnica</h3>
              </div>
            </div>
              </div>
          </div>
          <div class="col-md-6 active-gallery" id="galeria_proceso">
              <div class="pb-5 pt-5" *ngIf="galleryTwoPreview">
            <!--.card-gallery-->
            <div class="card-gallery cursor-pointer" style="background-image: url({{urlApi + galleryTwoPreview}})">
                <div class="d-none galleryTwoUrls" *ngFor="let url of galleryTwo;">
                <div class="d-none url-image">{{urlApi + url.upload_url}}</div>
                </div>
              <div class="card-gallery-content container">
                <h3 class="card-gallery-title">
                   Galería <br/>Proceso</h3>
              </div>
                  </div>
            </div>
          </div>
           
        </div>
        <!-- ./galerias-->
        <!-- ficha tecnica-->
        <div class="row pb-4" data-aos="fade-up" data-aos-duration="1200" >
            <div style="position: absolute;top:-100%;" #ficha_tecnica></div>
          <ul class="nav nav-tabs justify-content-center volcan-nav" role="tablist" *ngIf="dataOne">
            <li class="nav-item" *ngIf="dataOne"><a class="nav-link active" id="datos-tab" data-toggle="tab" href="#datos" role="tab" aria-controls="datos"  aria-selected="true">Datos</a></li>
            <li class="nav-item" *ngIf="creditsOne"><a class="nav-link" id="creditos-tab" data-toggle="tab" href="#creditos" role="tab" aria-controls="creditos" aria-selected="false">Créditos</a></li>
            <li class="nav-item" *ngIf="mediaOne"><a class="nav-link" id="medios-tab" data-toggle="tab" href="#medios" role="tab" aria-controls="medios" aria-selected="false" >Medios</a></li>
            <li class="nav-item" *ngIf="awardsOne"><a class="nav-link" id="premios-tab" data-toggle="tab" href="#premios" role="tab" aria-controls="premios" aria-selected="false">Premios</a></li>
          </ul>
          <div class="tab-content w-100 pt-0">
            <!-- datos-->
            <div class="tab-pane fade show active" id="datos" role="tabpanel" aria-labelledby="datos-tab">
              <div class="row">
                <div class="col-md-6 pr-xl-5">
                  <table class="table table-sm table-ficha-tecnica" style="position: relative;top: -1px;">
                    <tbody>
                      <tr *ngFor="let d of dataOne;" style="border-top: 1px solid #828282 !important;border-bottom: none !important;">
                        <td>{{d.data_content}}</td>
                        <td style="text-align: right">{{d.data_contentTwo}}</td>
                        
                      </tr>
              
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6 pl-xl-5">
                  <table class="table table-sm table-ficha-tecnica" style="position: relative;top: -1px;">
                    <tbody>
                          <tr *ngFor="let d of dataTwo;" style="border-top: 1px solid #828282 !important;border-bottom: none !important;">
                        <td>{{d.data_content}}</td>
                        <td style="text-align: right">{{d.data_contentTwo}}</td>
                        
                      </tr>
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- ./datos-->
            <!-- creditos-->
            <div class="tab-pane fade" id="creditos" role="tabpanel" aria-labelledby="creditos-tab">
              <div class="row">
                <div class="col-md-6 pr-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                            <tr style="border-bottom: none !important;" *ngFor="let d of creditsOne;">
                        <td>{{d.credit_content}}</td>
                        
                      </tr>
              
                     
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6 pl-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                           <tr style="border-bottom: none !important;" *ngFor="let d of creditsTwo;">
                        <td>{{d.credit_content}}</td>
                        
                      </tr>
              
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- ./creditos-->
            <!-- medios-->
            <div class="tab-pane fade" id="medios" role="tabpanel" aria-labelledby="medios-tab">
              <div class="row">
                <div class="col-md-6 pr-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                       <tr style="border-bottom: none !important;" *ngFor="let d of mediaOne;">
                        <td [innerHtml]="d.media_content"></td>
                        
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6 pl-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                       <tr style="border-bottom: none !important;" *ngFor="let d of mediaTwo;">
                        <td [innerHtml]="d.media_content"></td>
                        
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- ./medios-->
            <!-- premios-->
            <div class="tab-pane fade" id="premios" role="tabpanel" aria-labelledby="premios-tab">
              <div class="row">
                <div class="col-md-6 pr-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                    <tr style="border-bottom: none !important;" *ngFor="let d of awardsOne;">
                        <td>{{d.award_content}}</td>
                        
                      </tr>
              
                     
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6 pl-xl-5">
                  <table class="table table-sm table-ficha-tecnica">
                    <tbody>
                          <tr style="border-bottom: none !important;" *ngFor="let d of awardsTwo;">
                        <td>{{d.award_content}}</td>
                        
                      </tr>
              
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- ./premios-->
          </div>
        </div>
        <!-- ./ficha tecnica-->
        <!-- localizacion-->
        
        <div class="row mb-5" data-aos="fade-up" data-aos-duration="1200" id="content-map">
            <div style="position: absolute;top:-30%;" #localizacion></div>
            
          <div class="col-lg-6 pr-lg-0">
              
            <!-- Google map-->
                 <div class="map-container" id="map" style="height: 400px">
                   
                  
            </div>
            <!-- Google Maps-->
          </div>
          <div class="col-lg-6 localizacion-black" style="background: url('assets/img/fondo-mapa-proyecto.jpg'); background-repeat: no-repeat;background-size: cover;" *ngIf="project.project_locationText">
            <div class="py-4 pl-4 pr-5" [innerHtml]="project.project_locationText">
      
            </div>
          </div>
        </div>
      </section>
      <!-- ./main section-->
      <!-- Otros Proyectos-->
      <section class="container" *ngIf="relatedProjects">
        <div class="row py-4">
          <div class="col-12">
            <h3 class="h4">Otros Proyectos</h3>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-md-6 col-lg-4 pb-4" *ngFor="let project of relatedProjects;" >
              <a href="./proyecto/{{project.project_url}}">
            <div class="card-project other-projects card-project-sm project-zoom-effect"  style="background-image: url({{urlApi + project[0].image}});height: 236px;">
              <div class="card-project-content container">
                <h2 class="card-project-title" style="font-size: 24px;"> {{project.project_title}}</h2>
                <h3 class="card-project-subtitle" style="font-size: 16px;">{{project.project_subtitle}}</h3>
                <ul class="card-project-categories mb-0" style="font-size: 13px;">
                  <li>{{project.project_category}}</li>
                </ul>
              </div>
            </div>
                  </a>
          </div>

        </div>
      </section>
      <!-- ./Otros Proyectos-->
    </main>

 <app-footer></app-footer>