<main>
    <div class="space-top"></div>
      <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3" style="margin-left: -17px;">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">Servicios</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- main card-->
      <section class="row no-gutters" style="min-height: 60vh !important;">
        <div class="col-md-12">
       <mdb-carousel class="carousel-responsive-service" [animation]="'slide'">
  <mdb-carousel-item  *ngFor="let carrousel of serviceCarrousel; let first = first;">
<div class="home-card" style="background-image: url({{urlApi + carrousel.upload_url}})">
        
        </div>
  </mdb-carousel-item>
                  </mdb-carousel>
       
          <!-- ./card-project-->
        </div>
      </section>
      <!-- ./main card-->
      <!-- main section-->
      <section class="container">
        <!--description-->
        <div class="row py-5 mt-4 px-5 mb-4" >
          <div class="col-12" data-aos="fade-up" data-aos-duration="1200" [innerHtml]="service.service_content">
           
          </div>
        </div>
        <!-- ./description-->
      </section>
      <!--Sections-->
      <section class="container-fluid servicio-section" *ngFor="let sub of subservices" [style.background]="sub.sub_background" data-aos="fade-up" data-aos-duration="1200" >
        <div class="container">
          <!-- Row-->
          <div class="row">
            <div class="col-md-6">
              <h2 class="servicio-section-subtitle">{{sub.sub_title}}</h2>
              <p class="servicio-section-description" [innerHtml]="sub.sub_description"></p>
            </div>
            <div class="col-md-6">
              <div class="servicio-section-subpart" [innerHtml]="sub.sub_apart"> 
          
              </div>
            </div>
          </div>
          <!-- ./Row-->
        </div>
      </section>
     
    <div class="mt-5"></div>
      <!-- ./main section-->
    </main>

<app-footer></app-footer>