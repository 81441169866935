import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProjectsService } from '../../services/projects.service';
import {global} from  "../../services/globar";
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
  declare var Fresco: any; 
  declare var AOS: any; 
  declare var Dics: any; 
  declare var L: any;
  declare var Hammer: any; 
import * as $ from 'jquery';
class TechSpecMeta {
    project_title: null;
    
}
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
   
  providers: [ProjectsService]
})



export class ProjectComponent implements OnInit {

   public project;
   public textMap;
   public slider;
   public galleryOne;
   public upload_url;
   public galleryTwo;
   public urlApi;
   public galleryOnePreviw;
   public galleryTwoPreview;
   public comparative;
   public comparativeOne;
   public comparativeTwo;
   public comparativeThree;
   public dataOne;
   public dataTwo;
   public mediaOne;
   public mediaTwo;
   public creditsOne;
   public creditsTwo;
   public awardsOne;
   public awardsTwo;
   public relatedProjects;
   public project_latitude: number;
   public project_length: number;
   public showVideo: Boolean = true;
   public playVideo = true;
   public conditionVideo: String;
   public countSlider = 0;
   public markers = [
      {
       
          label: {
             color: 'red'
          },
          title: 'Ubicación',
      },

  ];
   options: AnimationOptions = {
    path: 'https://assets3.lottiefiles.com/private_files/lf30_NnBW3K.json',
  };

  constructor(
  private _projectService: ProjectsService,
  private route: ActivatedRoute,
  private _router: Router,
 
  ) { 
  this.urlApi = global.urlTow;
  this.project = new TechSpecMeta();
  
  }

  
  ngOnInit(): void {
   
   
    
  $('.main-header-nav').css('margin-left', '-15px');
  this.getProject();
  this.scripts();
  
  
  
  
window.onscroll = () => {
if(this.playVideo == true){
  /* Obtenemos la posición absoluta del elemento en cuestión */
  var div = document.getElementById('video-project');
  var yPos = div.getBoundingClientRect().top;
  /* Si está cerca del borde superior (pondremos un margen de 20px) mostramos el texto */
//console.log(yPos);
if(yPos < 300){
let audioPlayer = <HTMLVideoElement> document.getElementById('video-project');
audioPlayer.play();
this.playVideo = false;
}
}
 }

}

  
  scrollToElement($element): void {
    
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  
  startVideo(){
 
   let audioPause = <HTMLVideoElement> document.getElementById('video-project');
    audioPause.pause();
  }

  
  getProject(){
  
  this.route.params.subscribe(params => {
  let urlProject = params['url'];
  this._projectService.getProject(urlProject).subscribe(
  
  response => {
  this.project = response;
  if(this.project == null){
  this._router.navigate(['/proyectos']);
  }
  this.project_latitude = Number(this.project.project_latitude);
  this.project_length = Number(this.project.project_length);
  if( this.project_latitude){
  var map = L.map('map').setView([this.project.project_latitude, this.project_length], 16);
  
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);
  
  L.marker([this.project.project_latitude, this.project_length]).addTo(map)
      .bindPopup(this.project.project_title)
      .openPopup();
}else{
  document.getElementById("content-map").style.display="none";
}
  //Obtener slider
this._projectService.getSliderProject(this.project.project_token).subscribe(
  
  response => {
  this.slider = response;
  if(this.slider.length > 1){
  this.countSlider = 4000;
  }else{
   this.countSlider =0;
  }
  

  }
  );  
  
  //Obtener Galeria 1
this._projectService.getGalleryOneProject(this.project.project_token).subscribe(
  
  response => {
  this.galleryOne = response;
  this.galleryOnePreviw = this.galleryOne[0].upload_url;
  if(this.galleryOnePreviw == "undefined"){
  this.galleryOnePreviw = "";
  }
  }
  );
  
  //Obtener Galeria 2
this._projectService.getGalleryTwoProject(this.project.project_token).subscribe(
  
  response => {
  this.galleryTwo = response;
  this.galleryTwoPreview = this.galleryTwo[0].upload_url;
 if(this.galleryTwoPreview == "undefined"){
  this.galleryTwoPreview = "";
  }
  
  }
  );
  
  //Obtener comparativa
this._projectService.getComparativeProject(this.project.project_token).subscribe(
  
  response => {
  this.comparative = response;
  this.comparativeOne = this.comparative[0].upload_url;
  
  
   this.conditionVideo = this.comparativeOne.split(".")[this.comparativeOne.split(".").length - 1];
  
  if(this.conditionVideo == "jpg" || this.conditionVideo == "jpeg" || this.conditionVideo == "png" || this.conditionVideo == "JPG" || this.conditionVideo == "JPEG" || this.conditionVideo == "PNG"){

  this.showVideo = false;
  setTimeout(function(){ 
  if ($(".b-dics")[0]){
    new Dics({
      container: document.querySelector('.comparador_1')
    });
  }
}, 1000);
this.comparativeTwo = this.comparative[1].upload_url;
this.comparativeThree = this.comparative[2].upload_url;

  }else if(this.conditionVideo == "mp4" || this.conditionVideo == "MP4" || this.conditionVideo == "avi" || this.conditionVideo == "mov" || this.conditionVideo == "wmv"){
   this.comparativeThree = this.comparative[0].upload_url;
  }else{
    this.comparativeThree = this.comparative[2].upload_url;
  }
  
  this.comparativeTwo = this.comparative[1].upload_url;


 
  
  }
  ); 
  
  //Obtener datos Columna 1
this._projectService.getDataOneProject(this.project.project_token).subscribe(
  
  response => {
  this.dataOne = response;
  
  }
  );
  
  //Obtener datos Columna 2
this._projectService.getDataTwoProject(this.project.project_token).subscribe(
  
  response => {
  this.dataTwo = response;
  
  }
  );
  
  
  //Obtener medios Columna 1
this._projectService.getMediaOneProject(this.project.project_token).subscribe(
  
  response => {
  this.mediaOne = response;
  
  }
  );
  
  //Obtener medios Columna 2
this._projectService.getMediaTwoProject(this.project.project_token).subscribe(
  
  response => {
  this.mediaTwo = response;
  
  }
  );
  
  //Obtener Credits Columna 1
this._projectService.getCreditsOneProject(this.project.project_token).subscribe(
  
  response => {
  this.creditsOne = response;
  
  }
  );
  
  //Obtener Credits Columna 2
this._projectService.getCreditsTwoProject(this.project.project_token).subscribe(
  
  response => {
  this.creditsTwo = response;
  
  }
  ); 
  
  //Obtener Awards Columna 1
this._projectService.getAwardsOneProject(this.project.project_token).subscribe(
  
  response => {
  this.awardsOne = response;
  
  }
  );
  
  //Obtener Awards Columna 2
this._projectService.getAwardsTwoProject(this.project.project_token).subscribe(
  
  response => {
  this.awardsTwo = response;
  
  }
  );  
  
  //Obtener productos relacionados
this._projectService.getProjectCategory(this.project.project_id, this.project.project_category).subscribe(
  
  response => {
  this.relatedProjects = response;
  
  }
  );
  
  
  },
  
  
  error => {
  this._router.navigate(['/projects']);
  }
  
  ); 
  

  }); 
  

  
  }
  
  
  
  
  //Scripts que se imprimirán
  scripts(){

  
    //galeria 1
  $('#galeria_tecnica').on('click', function(event) {
    // the page will scroll up without this
    event.preventDefault();
  $("body").addClass("no-scroll");
var classList = document.querySelectorAll('.galleryOneUrls .url-image');
for (var i = 0; i < classList.length; i++) {
if(i == 0){
var url = [classList[i].innerHTML];
}else{
  url.push(classList[i].innerHTML); 
  }
}

   
     // Fresco API code goes here
    Fresco.show(
        url,
      {
        thumbnails: 'vertical',
        overlay: { close: false },
      
      }
    );

  });
  
  //Galería 2
  
    $('#galeria_proceso').click(function(event){
             
    // the page will scroll up without this
    event.preventDefault();
    
      
var classList = document.querySelectorAll('.galleryTwoUrls .url-image');
for (var i = 0; i < classList.length; i++) {
if(i == 0){
var url = [classList[i].innerHTML];
}else{
  url.push(classList[i].innerHTML); 
  }
}

    // Fresco API code goes here
    Fresco.show(
        url,
      {
        thumbnails: 'vertical',
        overlay: { close: false },
       
      }
    );

  });
  
  
  //scroll
    AOS.init({
        once: true,
    });

//Comparador



  
  }
playVideoIf(){

let audioPause = <HTMLVideoElement> document.getElementById('video-project');
audioPause.play();


}

}
