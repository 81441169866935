import { Component, OnInit } from '@angular/core';
declare var Swiper: any; 
declare var AOS: any; 
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   //scroll
    AOS.init({
        once: true,
    });
    
      
    //si existe la clase
  if ($(".swiper-container-columns-3")[0]){
    
    //slider de  4 columnas
    var swiper_columns_4 = new Swiper('.swiper-container-columns-3', {
      //slidesPerView: 2,
      spaceBetween: 10,
      //para evitar error de tabs
      observer:true,
      observeParents:true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      // init: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      
      }
    });
  }
  }


}
