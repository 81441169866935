import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {global} from  "./globar";

@Injectable()
export class TrainingService {

    public url: string;

    constructor(
    public _http: HttpClient
    ){
    this.url = global.url;
    }


    getTraining():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?training=true', {headers : headers});
    } 
    
    getTrainingCarrousel():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?trainingCarrousel=true', {headers : headers});
    }
    
    getTrainingSub():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?trainingSub=true', {headers : headers});
    }
    
  

}