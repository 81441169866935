import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [UserService]
})
export class RegisterComponent implements OnInit {
    public page_title: string;
 
  constructor(
  private _userService: UserService
  ) { 
  this.page_title = 'Registrate';
  
  }

  ngOnInit(): void {
  

  }
  
  onSubmit(form){

  }
  

  
}
