import { Component, OnInit } from '@angular/core';
import { HostListener} from "@angular/core";

import { ProjectsService } from '../../services/projects.service';
import {global} from  "../../services/globar";
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var materialSelect: any; 
declare var AOS: any; 
class SearchForm{
public search:string;
}
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [ProjectsService]
})
export class ProjectsComponent implements OnInit {
SearchModel = new SearchForm();
    public projects;
    public url;
    public empty;
    public percent;
    public limit = 14;
   
  timeLeft: number = 0;
  interval;
  constructor(
  private route: ActivatedRoute,
  private _router: Router,
  private _projectsService : ProjectsService
  ) { 
  this.url = global.urlTow;
 
  }


  @HostListener('document:scroll', ['$event'])
  onScroll(event) {

   if (100 * $(window).scrollTop() / ($(document).height() - $(window).height()) > 70) {
this.route.params.subscribe(params => {
  let category = params['category'];
  let search = params['search'];
    if(this.timeLeft == 0 && category == undefined && search == undefined){

      this.limit= this.limit +14;
      
       this.getProjects();  
       this.startTimer();
       this.timeLeft = 2;
       }
       });
   
    }
    
    
  }

startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        console.log(this.timeLeft);
      } else {
        this.pauseTimer();
      }
    },2200)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  ngOnInit(): void {

  $('.main-header-nav').css('margin-left', '-15px');
       //scroll
    AOS.init({
        once: true,
    });
  this.getProjects();
  (<any>$('.mdb-select')).materialSelect();
  }

  
  
  onSubmit(){
   
   this._router.navigateByUrl('/proyectos/buscar/'+this.SearchModel.search);
}

 change(percent: string){
  
 this._router.navigateByUrl('/proyectos/'+percent);
    }


  getProjects(){
    this.route.params.subscribe(params => {
  let category = params['category'];
  let search = params['search'];
  
  //Resultados de busqueda
  if(search != '' && category == undefined && search != undefined){
    this._projectsService.getProjectsSearch(search).subscribe(
  
  response => {
  this.projects = response;
 
    if(this.projects === null && search){
   
    this.empty = '<div class="container-full container pt-3"><h1>No se han encontrado resultados</h1></div>';
    
    }else{
    this.empty = '';
    }

  }
  );
  
  }
  
  //Resultado por categorías
  if(category != '' && category != undefined){
  this._projectsService.getProjectsCategory(category).subscribe(
  
  response => {
  this.projects = response;
  
   if(this.projects === null && category){
   
    this.empty = '<div class="container-full container pt-3"><h1>No existen contenidos para esta categoría</h1></div>';
    
    }else{
    this.empty = '';
    }
  

  }
  );
  }
  
  //Todos los proyectos
  if(category == undefined && search == undefined){
  
  this._projectsService.getProjects(this.limit).subscribe(
  
   response => {
   this.projects = response;

  }
  );
  }
  
  });
  

  
  }

}
