<main>
      <!--espacio para el header-->
      <div class="space-top"></div>
      <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">Privacidad: Aviso Legal y Condiciones Generales de Uso</h1>
            </div>
          </div>
        </div>
      </section>

      <!-- main section-->
      <section class="container">
        <!--description-->
        <div class="row py-4 mt-5">

          <div class="col-12 font-weight-light" style="color: #404040;font-size: 16px;font-weight: 300;line-height: 30px;" >
              <div data-aos="fade-up" data-aos-duration="1200">
              <h2>I. INFORMACIÓN GENERAL</h2>
            <p>En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de
Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de
11 de julio, se facilitan a continuación los siguientes datos de información general de
este sitio web:</p>
           <p>La titularidad de este sitio web, www.volcanstudio.com, (en adelante, Sitio Web) la
ostenta: VOLCÁN STUDIO S.L., provista de NIF: B-76307909 e inscrita en: Registro
Mercantil de Las Palmas con los siguientes datos registrales: Tomo 2184 Folio 68
Hoja 53695, Inscripción 1ª, cuyo representante es: Jorge Manuel Torres García, y
cuyos datos de contacto son:</p>
    <p>Dirección:</p>
              <p>Teléfono de contacto: 660 674 355 <br>Email de contacto: info@volcanstudio.com</p>
              <h2>II. TÉRMINOS Y CONDICIONES GENERALES DE USO</h2>
              <p><b>El objeto de las condiciones: El Sitio Web</b></p>
              <p>El objeto de las presentes Condiciones Generales de Uso (en adelante,
Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las
presentes Condiciones se entenderá como Sitio Web: la apariencia externa de los
interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el
árbol de navegación; y todos los elementos integrados tanto en los interfaces de
pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos
servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante,
Servicios).</p>
              <p>Volcán Studio se reserva la facultad de modificar, en cualquier momento, y sin aviso
previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios
que en él pudieran estar incorporados. El Usuario reconoce y acepta que en
cualquier momento Volcán Studio pueda interrumpir, desactivar y/o cancelar
cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los
mismos.</p>
              <p>El acceso al Sitio Web por el Usuario tiene carácter libre y, por regla general, es
gratuito sin que el Usuario tenga que proporcionar una contraprestación para poder
disfrutar de ello, salvo en lo relativo al coste de conexión a través de la red de

2/1
telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado
el Usuario.</p>
              <p>La utilización de los Contenidos no requiere previa suscripción o registro alguno.</p>
              </div>
              <div data-aos="fade-up" data-aos-duration="1200">
              <h2>El Usuario</h2>
              <p>El acceso, la navegación y uso del Sitio Web, así como por los espacios habilitados
para interactuar entre los Usuarios, y el Usuario y Volcán Studio, como los
comentarios y/o espacios de blogging, confiere la condición de Usuario, por lo que
se aceptan, desde que se inicia la navegación por el Sitio Web, todas las
Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio
de la aplicación de la correspondiente normativa legal de obligado cumplimiento
según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas
cada vez que visite el Sitio Web.</p>
              <p>El Sitio Web de Volcán Studio proporciona gran diversidad de información, servicios
y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio
Web. Esta responsabilidad se extenderá a:</p>
              <ul>
              <li><p>Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por
Volcán Studio sin que sea contrario a lo dispuesto por las presentes
Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo
puedan suponer lesión de los derechos de terceros o del mismo
funcionamiento del Sitio Web.</p></li>
              </ul>
              <p>Volcán Studio se reserva el derecho de retirar todos aquellos comentarios y
aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean
discriminatorios, xenófobos, racistas, pornográficos, spamming, que atenten contra
la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no
resultaran adecuados para su publicación.</p>
              <p>En cualquier caso, Volcán Studio no será responsable de las opiniones vertidas por
los Usuarios a través de comentarios u otras herramientas de blogging o de
participación que pueda haber.</p>
              <p>El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de
carácter comercial entre Volcán Studio y el Usuario.</p>
              <p>El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente
para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de
Volcán Studio no se dirige a menores de edad. Volcán Studio declina cualquier
responsabilidad por el incumplimiento de este requisito.</p>
              <p>El Sitio Web está dirigido principalmente a Usuarios residentes en España. Volcán
Studio no asegura que el Sitio Web cumpla con legislaciones de otros países, ya sea
total o parcialmente. Si el Usuario reside o tiene su domiciliado en otro lugar y decide
acceder y/o navegar en el Sitio Web lo hará bajo su propia responsabilidad, deberá
asegurarse de que tal acceso y navegación cumple con la legislación local que le es
aplicable, no asumiendo Volcán Studio responsabilidad alguna que se pueda derivar
de dicho acceso.</p>
              </div>
              <div data-aos="fade-up" data-aos-duration="1200">
              <h2>III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y

RESPONSABILIDAD</h2>
              <p>Volcán Studio no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni
de los Contenidos o Servicios. Volcán Studio hará todo lo posible por el buen
funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el
acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.</p>
              <p>Tampoco se responsabiliza o garantiza que el contenido o software al que pueda
accederse a través de este Sitio Web, esté libre de error o cause un daño al sistema
informático (software y hardware) del Usuario. En ningún caso Volcán Studio será
responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el
acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los
ocasionados a los sistemas informáticos o los provocados por la introducción de
virus.</p>
              <p>Volcán Studio tampoco se hace responsable de los daños que pudiesen ocasionarse
a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace
responsable en modo alguno de las caídas, interrupciones, falta o defecto de las
telecomunicaciones que pudieran ocurrir.</p>
              </div>
                  <div data-aos="fade-up" data-aos-duration="1200">
              <h2>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h2>
              <p>Respetando lo establecido en la legislación vigente, Volcán Studio se compromete a
adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad
adecuado al riesgo de los datos recogidos.</p>
              <p><b>Leyes que incorpora esta política de privacidad</b></p>
              <p>Esta política de privacidad está adaptada a la normativa española y europea vigente
en materia de protección de datos personales en internet. En concreto, la misma
respeta las siguientes normas:</p>
              <ul>
              <li><p>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
de abril de 2016, relativo a la protección de las personas físicas en lo que
respecta al tratamiento de datos personales y a la libre circulación de estos
datos (RGPD).</p></li>
              <li><p>La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
Personales y garantía de los derechos digitales (LOPD-GDD).</p></li>
              <li><p>El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el
Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de
Protección de Datos de Carácter Personal (RDLOPD).</p></li>
              <li><p>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información
y de Comercio Electrónico (LSSI-CE).</p></li>
                  </ul>
                  </div>
              <div data-aos="fade-up" data-aos-duration="1200">
                  <h2>V. POLÍTICA DE ENLACES</h2>
                  <p>Se informa que el Sitio Web de Volcán Studio pone o puede poner a disposición de
los Usuarios medios de enlace (como, entre otros, links, banners, botones),
directorios y motores de búsqueda que permiten a los Usuarios acceder a sitios web
pertenecientes y/o gestionados por terceros.</p>
                  <p>La instalación de estos enlaces, directorios y motores de búsqueda en el Sitio Web
tiene por objeto facilitar a los Usuarios la búsqueda de y acceso a la información
disponible en Internet, sin que pueda considerarse una sugerencia, recomendación o
invitación para la visita de los mismos.</p>
                  <p>Volcán Studio no ofrece ni comercializa por sí ni por medio de terceros los productos
y/o servicios disponibles en dichos sitios enlazados.</p>
                  <p>Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad,
validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por
medio de los enlaces.</p>
                  <p>Volcán Studio en ningún caso revisará o controlará el contenido de otros sitios web,
así como tampoco aprueba, examina ni hace propios los productos y servicios,
contenidos, archivos y cualquier otro material existente en los referidos sitios
enlazados.</p>
                  <p>Volcán Studio no asume ninguna responsabilidad por los daños y perjuicios que
pudieran producirse por el acceso, uso, calidad o licitud de los contenidos,
comunicaciones, opiniones, productos y servicios de los sitios web no gestionados
por Volcán Studio y que sean enlazados en este Sitio Web.</p>
                  <p>El Usuario o tercero que realice un hipervínculo desde una página web de otro,
distinto, sitio web al Sitio Web de Volcán Studio deberá saber que:</p>
                  <p>No se permite la reproducción —total o parcialmente— de ninguno de los
Contenidos y/o Servicios del Sitio Web sin autorización expresa de Volcán Studio.</p>
                  <p>No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el
Sitio Web de Volcán Studio, ni sobre los Contenidos y/o Servicios del mismo.</p>
                  <p>A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace
no contendrá ningún elemento, de este Sitio Web, protegido como propiedad
intelectual por el ordenamiento jurídico español, salvo autorización expresa de
Volcán Studio.</p>
                  <p>El establecimiento del hipervínculo no implicará la existencia de relaciones entre
Volcán Studio y el titular del sitio web desde el cual se realice, ni el conocimiento y
aceptación de Volcán Studio de los contenidos, servicios y/o actividades ofrecidos
en dicho sitio web, y viceversa.</p>
              </div>
              <div data-aos="fade-up" data-aos-duration="1200">
                  <h2>VI. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
                  <p>Volcán Studio por sí o como parte cesionaria, es titular de todos los derechos de
propiedad intelectual e industrial del Sitio Web, así como de los elementos
contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes, sonido,
audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores,
estructura y diseño, selección de materiales usados, programas de ordenador
necesarios para su funcionamiento, acceso y uso, etc.). Serán, por consiguiente,
obras protegidas como propiedad intelectual por el ordenamiento jurídico español,
siéndoles aplicables tanto la normativa española y comunitaria en este campo, como
los tratados internacionales relativos a la materia y suscritos por España.</p>
                  <p>Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad
Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la
comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad
o parte de los contenidos de esta página web, con fines comerciales, en cualquier
soporte y por cualquier medio técnico, sin la autorización de Volcán Studio.</p>
                  <p>El Usuario se compromete a respetar los derechos de propiedad intelectual e
industrial de Volcán Studio. Podrá visualizar los elementos del Sitio Web o incluso
imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en
cualquier otro soporte físico siempre y cuando sea, exclusivamente, para su uso
personal. El Usuario, sin embargo, no podrá suprimir, alterar, o manipular cualquier
dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio
Web.</p>
                  <p>En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del
Sitio Web suponga una violación de los derechos de protección de la propiedad
intelectual, deberá comunicarlo inmediatamente a Volcán Studio a través de los
datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y
Condiciones Generales de Uso.</p>
              </div>
              <div data-aos="fade-up" data-aos-duration="1200">
                  <h2>VII. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
                  <p>Volcán Studio se reserva la facultad de presentar las acciones civiles o penales que
considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el
incumplimiento de las presentes Condiciones.</p>
                  <p>La relación entre el Usuario y Volcán Studio se regirá por la normativa vigente y de
aplicación en el territorio español. De surgir cualquier controversia en relación a la
interpretación y/o a la aplicación de estas Condiciones las partes someterán sus
conflictos a la jurisdicción ordinaria sometiéndose a los jueces y tribunales que
correspondan conforme a derecho.</p>
                  <p>Ultima modificación: 15 de junio 2020</p>
              </div>
          </div>
        </div>
        <!-- ./description-->
      </section>

      <!-- ./main section-->
    </main>
 <app-footer></app-footer>