import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {global} from  "./globar";

@Injectable()
export class StudyService {

    public url: string;

    constructor(
    public _http: HttpClient
    ){
    this.url = global.url;
    }


    getStudy():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?study=true', {headers : headers});
    }
    
     getStudyCarrousel():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?studyCarrousel=true', {headers : headers});
    }
    
      getStudyAwards():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?studyAwards=true', {headers : headers});
    }
    
    getStudyTeam():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?studyTeam=true', {headers : headers});
    } 
    
    getStudyImgRsc():Observable<any>{
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'api.php?studyImgRsc=true', {headers : headers});
    }
   

}