    <main>
 
  <mdb-carousel #carousell [animation]="'slide'" >
  <mdb-carousel-item  *ngFor="let carrousel of slider; let first = first;">
          <div class="home-card" style="background-image: url({{urlApi + carrousel[0].image}})" >
          <div class="home-card-content container">
            <h2 class="home-card-title left-important"> <a [routerLink]="['../proyecto', carrousel.project_url]">{{carrousel.project_title}}</a></h2>
            <h3 class="home-card-subtitle left-important">{{carrousel.project_subtitle}}</h3>
            <ul class="home-card-categories left-important">
              <li>{{carrousel.project_category}}</li>
           
            </ul>
          </div>
        </div>
  </mdb-carousel-item>

</mdb-carousel>
  
      <!-- ./home-cards-->
      <div class="position-relative"></div>
      <footer class="container-fluid home-footer left-important">
        <div class="footer-home footer-home-position container">
          <div class="row" style="font-size: 11px;">
            <div class="col-lg-4 home-footer-left">
              <p>&copy; Copyright {{year}} Volcán Studio, Todos los derechos reservados.</p>
            </div>
            <!--puntos de colores, descomentar por si son necesarios-->
            <div class="col-lg-4 home-card-points">
        
            </div>
            <div class="col-lg-4 ml-auto home-footer-right">
              <ul class="home-footer-right-links">
                
                <li><a [routerLink]="['../privacidad']">Privacidad</a></li>
                
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </main>
