import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
declare var Hammer: any;
// Google Maps de Angular 9 
import {GoogleMapsModule} from '@angular/google-maps'; 
import { routing, appRoutingProviders } from './app.routing';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { StudyComponent } from './components/study/study.component';
import { TrainingComponent } from './components/training/training.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProjectComponent } from './components/project/project.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/slider/slider.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { BlogComponent } from './components/blog/blog.component';
import { PostComponent } from './components/post/post.component';
import { ServicesComponent } from './components/services/services.component';
export function playerFactory() {
  return player;
}
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ErrorComponent,
    ProjectsComponent,
    StudyComponent,
    TrainingComponent,
    ContactComponent,
    ProjectComponent,
    FooterComponent,
    SliderComponent,
    PrivacyComponent,
    BlogComponent,
    PostComponent,
    ServicesComponent
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    HammerModule,
     MDBBootstrapModule.forRoot(),
     LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
  appRoutingProviders,
  {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


