<main>
    <!--espacio para el header-->
       <div class="space-top"></div>
 
     
        <div class="container-full container ">
       <div class="row pt-3" style="margin-right: -15px !important;
    margin-left: -15px !important;">
          <div class="col-md-12 col-lg-3 text-center text-md-left">
            <h1 class="page-title">Proyectos</h1>
          </div>
        
          <div class="col-md-6 col-lg-4"></div>
          <div class="col-md-6 col-lg-4">
            <!-- Search form-->
            <form class="form-inline d-flex justify-content-center md-form form-sm mt-0"><i class="fas fa-search" aria-hidden="true"></i>
              <input class="form-control form-control-sm ml-3 w-75" type="text" placeholder="Buscar" aria-label="Buscar"/>
            </form>
                </div>
          </div>
        </div>
        <!--all cards-->
       <section class="row no-gutters">
        <div class="col-md-12">
          <!--.card-project-->
          <div class="card-project card-noticia-main-img" style="background-image: url(assets/img/home/carousel/servicios_agencia.jpg)"></div>
          <!-- ./card-project-->
        </div>
      </section>
      <!-- ./main card-->
      <div class="container-fluid noticias-bg">
        <div class="container noticias-bg-content">
          <!-- main section-->
          <section class="container">
            <div class="row card-noticia-row">
              <div class="col-12">
                <h1 class="card-noticia-title">Titulo Noticia</h1>
                <h2 class="card-noticia-subtitle">Subtitulo noticia</h2>
                <ul class="card-project-categories">
                  <li>Categoria 1<span class="mr-1">, </span></li>
                  <li>Categoria 2</li>
                </ul>
              </div>
            </div>
            <!--description-->
            <div class="row py-4 noticias-space" data-aos="fade-up" data-aos-duration="1200">
              <div class="col-12">
                <p class="font-weight-light">29 agosto 2018 - Maria delgado</p>
              </div>
              <div class="col-12">
                <p>Reforma de un ático caracterizada por la rapidez tanto en el diseño y planificación como en su ejecución. El proyecto de reforma completo se realizó en el plazo acordado de 40 días incluyendo como trabajos principales la redistribución de espacios, reformas de baño, cocina y dormitorio, instalación de jacuzzi en terraza, cambio de piso, reforma de la instalación eléctrica e iluminación, pintura y decoración interior.</p>
              </div>
              <div class="col-12 font-weight-light">
                <p>La vivienda cuenta con un total de 300 m2 distribuidos en vivienda (130 m2) y terraza (170 m2). Para adecuar el espacio a las necesidades y forma de vida de su propietario se modificaron la distribución inicial del apartamento y los accesos generando espacios individualmente luminosos y singulares y, en conjunto, conformasen una vivienda extraordinaria.</p>
                <p>Para ello se proyectaron tres modificaciones integrales.</p>
                <ul>
                  <li>1- Creación de un dormitorio en suite con dos áreas diferenciadas: descanso y aseo-vestidor.  </li>
                  <li>2- Unión sala de estar-cocina para crear un espacio amplio y luminoso de estar.</li>
                  <li>3- Construcción de una zona chill out en la terraza con barbacoa y jacuzzi.</li>
                </ul>
                <p>En cuanto a superficies y materiales, se optó por eliminar las molduras y rodapiés y unificar acabados y pavimentos. Uso de maderas naturales en tonos neutros y cerámicas destonificadas configurando un perfecto espacio minimalista nórdico.</p>
                <p>El resultado es una vivienda totalmente diferente a la inicial, caracterizada por su luminosidad, con grandes espacios y formas puras.</p>
              </div>
            </div>
            <!-- ./description-->
            <!-- galerias-->
            <div class="row justify-content-between pb-5 pt-5" id="galerias" data-aos="fade-up" data-aos-duration="1200">
              <div class="col-12">
                <!--.card-gallery-->
                <div class="card-gallery cursor-pointer" id="galeria_tecnica" style="background-image: url(assets/img/estudio/galeria_tecnica.jpg)"></div>
              </div>
              <div class="col-12">
                <p class="font-weight-light noticias-space pt-1">29 agosto 2018 - Maria delgado</p>
              </div>
            </div>
            <!-- ./galerias-->
            <!--description-->
            <div class="row py-4 noticias-space" data-aos="fade-up" data-aos-duration="1200">
              <div class="col-12">
                <p>Reforma de un ático caracterizada por la rapidez tanto en el diseño y planificación como en su ejecución. El proyecto de reforma completo se realizó en el plazo acordado de 40 días incluyendo como trabajos principales la redistribución de espacios, reformas de baño, cocina y dormitorio, instalación de jacuzzi en terraza, cambio de piso, reforma de la instalación eléctrica e iluminación, pintura y decoración interior.</p>
              </div>
              <div class="col-12 font-weight-light">
                <p>La vivienda cuenta con un total de 300 m2 distribuidos en vivienda (130 m2) y terraza (170 m2). Para adecuar el espacio a las necesidades y forma de vida de su propietario se modificaron la distribución inicial del apartamento y los accesos generando espacios individualmente luminosos y singulares y, en conjunto, conformasen una vivienda extraordinaria.</p>
                <p>Para ello se proyectaron tres modificaciones integrales.</p>
                <ul>
                  <li>1- Creación de un dormitorio en suite con dos áreas diferenciadas: descanso y aseo-vestidor.  </li>
                  <li>2- Unión sala de estar-cocina para crear un espacio amplio y luminoso de estar.</li>
                  <li>3- Construcción de una zona chill out en la terraza con barbacoa y jacuzzi.</li>
                </ul>
                <p>En cuanto a superficies y materiales, se optó por eliminar las molduras y rodapiés y unificar acabados y pavimentos. Uso de maderas naturales en tonos neutros y cerámicas destonificadas configurando un perfecto espacio minimalista nórdico.</p>
                <p>El resultado es una vivienda totalmente diferente a la inicial, caracterizada por su luminosidad, con grandes espacios y formas puras.</p>
              </div>
            </div>
            <!-- ./description-->
            <!--comparte-->
            <div class="row noticias-space">
              <div class="col-12">
                <hr/>
              </div>
              <div class="col-12">
                <p class="font-weight-light">Comparte en tus redes</p>
              </div>
            </div>
            <div class="row noticias-space-2 pb-3">
              <div class="col-lg-4"><a class="btn btn-fb btn-block mb-4" href=""><i class="fab fa-facebook-f pr-1"></i> Facebook</a></div>
              <div class="col-lg-4"><a class="btn btn-tw btn-block mb-4" href=""><i class="fab fa-twitter pr-1"></i> Twitter</a></div>
              <div class="col-lg-4"><a class="btn btn-li btn-block mb-4" href=""><i class="fab fa-linkedin-in pr-1"></i> Linkedin</a></div>
            </div>
            <!--.comparte-->
            <!--autor-->
            <div class="row noticias-space">
              <div class="col-12">
                <hr/>
              </div>
            </div>
            <div class="row card-author noticias-space-2">
              <!-- Grid column-->
              <div class="col-lg-2">
                <!-- Image-->
                <div class="mb-3 card-author-img-content"><img class="card-author-img z-depth-1" src="https://mdbootstrap.com/img/Photos/Others/photo12.jpg" alt="Post"/></div>
              </div>
              <!-- Grid column-->
              <!-- Second column-->
              <div class="col-lg-10 mb-1">
                <!-- Post data-->
                <div>
                  <p class="card-author-title">Autor Apellidos</p>
                  <p class="card-author-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde odit, numquam, rem consequatur quo voluptates dolores</p>
                </div>
              </div>
            </div>
            <div class="row noticias-space">
              <div class="col-12">
                <hr/>
              </div>
            </div>
            <!--./autor-->
          </section>
          <!-- ./main section-->
          <!-- Otros Proyectos-->
          <section class="container" data-aos="fade-up" data-aos-duration="1200">
            <div class="row py-4">
              <div class="col-12">
                <h3 class="h4">Otros Articulos</h3>
              </div>
            </div>
            <div class="swiper-container mb-5 swiper-container-columns-3">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="card-project card-project-xs" style="background-image: url(assets/img/estudio/galeria_proceso.jpg)">
                    <div class="card-project-content container">
                      <h2 class="card-project-title"> <a href="#">Título del proyecto 1</a></h2>
                      <h3 class="card-project-subtitle">Subtitulo del Proyecto</h3>
                      <ul class="card-project-categories mb-0">
                        <li>Categoria</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card-project card-project-xs" style="background-image: url(assets/img/estudio/galeria_proceso.jpg)">
                    <div class="card-project-content container">
                      <h2 class="card-project-title"> <a href="#">Título del proyecto 2</a></h2>
                      <h3 class="card-project-subtitle">Subtitulo del Proyecto</h3>
                      <ul class="card-project-categories mb-0">
                        <li>Categoria</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card-project card-project-xs" style="background-image: url(assets/img/estudio/galeria_proceso.jpg)">
                    <div class="card-project-content container">
                      <h2 class="card-project-title"> <a href="#">Título del proyecto 3</a></h2>
                      <h3 class="card-project-subtitle">Subtitulo del Proyecto</h3>
                      <ul class="card-project-categories mb-0">
                        <li>Categoria</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card-project card-project-xs" style="background-image: url(assets/img/estudio/galeria_proceso.jpg)">
                    <div class="card-project-content container">
                      <h2 class="card-project-title"> <a href="#">Título del proyecto 4</a></h2>
                      <h3 class="card-project-subtitle">Subtitulo del Proyecto</h3>
                      <ul class="card-project-categories mb-0">
                        <li>Categoria</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </section>
          <!-- ./Otros Proyectos-->
        </div>
      </div>
     
    </main>

 <app-footer></app-footer>