import { Component, OnInit } from '@angular/core';
import {global} from  "../../services/globar";
import { TrainingService } from '../../services/training.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var AOS: any;
declare var Hammer: any; 
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css'],
  providers: [TrainingService]
})
export class TrainingComponent implements OnInit {
    public dominio;
    public urlApi;
    public training;
    public trainingCarrousel;
    public trainingSub;
    public urlApiClean;
  constructor(
   private route: ActivatedRoute,
  private _router: Router,
  private _trainingService : TrainingService,
  ) {
  this.dominio = global.dominio;
  this.urlApi = global.urlTow;
  this.urlApiClean = global.url;
  }

  ngOnInit(): void {

  
  this.getTraining();
  
  $('.main-header-nav').css('margin-left', '-15px');

    //scroll
    AOS.init({
        once: true,
    });
    
    
$(document).ready(function(){

(<any>$('#carousel')).carousel({
interval:10000
});
      setTimeout(function(){
   $('.active > .video-fluid').each(function( index ) {
      
      this.play();
    });
    

    }, 3000);
  
});
  $('#carousel').on('slide.bs.carousel', function () {
   
   setTimeout(function(){
       $('.video-fluid').each(function( index ) {
      
      this.pause();
    });
   
      $('.active > .playv').each(function( index ) {
      
      this.play();
    });

    }, 1700);
    });
  }

  activeSlideChange(){
    $('.active > .video-fluid').each(function( index ) {
      
      this.currentTime = 0;
    
    });
  }

heightVideo(){
  var heightIf = 1080 * $(window).width() / 1920;
if(heightIf > $(window).height()){
var height = $(window).height() - 136;
$('.carousel').css('height', height);
$('.carousel').css('overflow', 'hidden');
}else{
 var height = 1080 * $(window).width() / 1920;
$('.carousel').css('height', height);
$('.carousel').css('overflow', 'hidden');
}

$('.active > .video-fluid').each(function( index ) {

this.play();
});

  }

getTraining(){
 this.route.params.subscribe(params => {
    this._trainingService.getTraining().subscribe(
  
  response => {
  this.training = response;
  
  }
  );  
  
  this._trainingService.getTrainingCarrousel().subscribe(
  
  response => {
  this.trainingCarrousel = response;
  
  }
  ); 
  
  
  this._trainingService.getTrainingSub().subscribe(
  
  response => {
  this.trainingSub = response;
 
  }
  );
  });
}


}
