import { Component, OnInit } from '@angular/core';
import {global} from  "../../services/globar";
import { ContactService } from '../../services/contact.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
declare var AOS: any; 
declare var L: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [ContactService]
})
export class ContactComponent implements OnInit {
    public contact;
    public contact_latitude: Number;
   public contact_length: Number;
      public markers = [
      {
       
          label: {
             color: 'red'
          },
          title: 'Ubicación',
         
      },

  ];
  constructor(
   private route: ActivatedRoute,
  private _router: Router,
  private _contactService : ContactService,
  ) { }


// Configuración de Google Maps
 
 
  ngOnInit(): void {
  this.getContact();



 $('.main-header-nav').css('margin-left', '-15px');
  //scroll
    AOS.init({
        once: true,
    });
  }
  getContact(){
 this.route.params.subscribe(params => {
    this._contactService.getContact().subscribe(
  
  response => {
  this.contact = response;
  console.log(this.contact);
    this.contact_latitude = Number(this.contact.contact_latitude);
  this.contact_length = Number(this.contact.contact_length);
  
  var map = L.map('map').setView([this.contact_latitude, this.contact_length], 16);
  
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);
  
  L.marker([this.contact_latitude, this.contact_length]).addTo(map)
      .bindPopup("Volcán studio")
      .openPopup();
  }
  );  
  
  });
}

}
