<main>
      <!--espacio para el header-->
      <div class="space-top"></div>
      <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3" style="margin-left: -17px;">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">Formación</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- main card-->
      <section class="row no-gutters min-header">
        <div class="col-md-12">
          <!--.card-project-->
              <mdb-carousel  #carouselRef [animation]="'slide'" [interval]="0" (activeSlideChange)="activeSlideChange()">
  <mdb-carousel-item *ngFor="let carrousel of trainingCarrousel; let first = first;">
    <video class="video-fluid" preload muted="true" (ended)="carouselRef.nextSlide()" (loadstart)="heightVideo()"  playsinline src="{{urlApi + carrousel.upload_url}}"></video>
  </mdb-carousel-item>

</mdb-carousel>
            
       
          <!-- ./card-project-->
        </div>
      </section>
      <!-- ./main card-->
      <!-- main section-->
      <section class="container">
        <!--description-->
        <div class="row py-4 mt-5" >
          <div class="col-12" data-aos="fade-up" data-aos-duration="1200" style="color: #404040;font-size: 17px;font-weight: 400;line-height: 30px;" [innerHtml]="training.training_intro">
    
          </div>
          <div class="col-12 font-weight-light" data-aos="fade-up" data-aos-duration="1200" style="color: #404040;font-size: 16px;font-weight: 300;line-height: 30px;" [innerHtml]="training.training_content">
         
           
    
          </div>
        </div>
        <!-- ./description-->
      </section>
      <!--Formacion sections-->
      <section class="container-fluid py-5 mb-4">
        <!-- Formación Online-->
        <div class="row" data-aos="fade-up" data-aos-duration="1200" *ngFor="let sub of trainingSub;">
          <div class="col-md-6 px-0">
            <div class="card-project image-training project-zoom-effect" style="background-image: url({{urlApiClean + sub.sub_image}});display: flex; justify-content: center;align-items: center;text-align: center;">
              <div class="card-project-content container" style="position: relative;padding: 0px !important;bottom: 0px;">
                <h2 class="card-project-title">
                   {{sub.sub_title}}</h2>
              
              </div>
            </div>
          </div>
          <div class="col-md-6 formacion-right" [style.background]="sub.sub_background">
            <div style="color: #404040;font-size: 15px;font-weight: 300;line-height: 25px;" [innerHtml]="sub.sub_text">
            
            </div>
          </div>
        </div>
        <!-- ./Formación Online-->
 
      </section>
      <!-- ./main section-->
    </main>
 <app-footer></app-footer>