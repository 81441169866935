<main>
      <!--espacio para el header-->
      <div class="space-top"></div>
      
     <section class="container-fluid bg-title">
        <div class="container-full container">
          <div class="row pt-3 pb-3">
            <div class="col-md-12 text-center text-md-left">
              <h1 class="page-title">{{page_title}}</h1>
            </div>
          </div>
        </div>
      </section>
</main>
 <app-footer></app-footer>